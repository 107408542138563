import router from "@/router";
import { createApp } from 'vue';

import VueGoogleMaps from "custom-google-service";
import globalComponents from '@/components/index'
import {VueReCaptcha} from "vue-recaptcha-v3";

export const initApp = (selector, view) => {
    try {
        const createVueApp = (id, innerHtml) => {
            const app = createApp(view, {...window[`${id}`]})

            globalComponents.forEach(component => app.component(component.name, component.component));

            app.provide('serverHtml', innerHtml)
                .use(router)
                .use(VueGoogleMaps, {
                    load: {
                        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
                        libraries: ["places", "marker"],
                    },
                })
                .use(VueReCaptcha, { siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY })
                .mount(`#${id}`)
        }

        window.addEventListener(`widget-inserted-${selector.slice(1)}`,
            event => createVueApp(event.detail.id, ""))

        const apps = Array.from(document.querySelectorAll(selector))

        for (let i = 0; i < apps.length; i++) {
            createVueApp(apps[i].id, apps[i].innerHTML)
        }
    }
    catch (e) {
        console.error(e)
    }
}

export const chunkArray = (arr, size) => {
    return arr
        .reduce((acc, _, i) =>
            (i % size) ?
                acc :
                [...acc, arr.slice(i, i + size)], []);
}

export const handleListeners = (pageEvents, callback, remove) => {
    !remove && pageEvents.forEach(pageEvent => window.addEventListener(pageEvent, callback))
    remove && pageEvents.forEach(pageEvent => window.removeEventListener(pageEvent, callback))
}

export const scrollTop = () => window.scrollTo(0, 0)

export const parseLocation = (place, callback) => {
    const service = new google.maps.places.PlacesService(document.createElement("div"));
    const request = {
        placeId: place.place_id,
        fields: ['address_components', 'geometry', 'formatted_address']
    }

    service.getDetails(request, (details) => {
        let info = {};
        details?.address_components?.forEach(entry => {
            info[entry.types?.[0]] = entry.long_name
        })

        callback({
            latitude: details.geometry.location.lat(),
            longitude: details.geometry.location.lng(),
            city: info.locality,
            formatted_address: details.formatted_address,
            info
        })
    })
}

export const handlePaginateClick = (options, page, router) => {
    options.value.params.page = page
    router.push({ query: { ...router.currentRoute.value.query, id: page } })
}

export const getHeaderAlertHeight = () => {
  const alert = document.querySelector('.header-alert');

  if (alert) {
    return alert.offsetHeight;
  } else {
    return 0;
  }
}

export const isExternalLink = (target) => {
    const link = (target || '')
    return link.includes('https') ||
            link.includes('www') ||
            link.includes('.')
}

export const handleLocation = (state, place, additional, addressField, usaStates, canadaStates, updateStateOptions = true) => {

    parseLocation(place, (locationInfo) => {
        const stateOptions = locationInfo.info.country === 'United States' ? usaStates : canadaStates
        const country = locationInfo.info.country === 'United States' ? 'USA' : 'Canada'
        const stateName = stateOptions.filter(state => state === locationInfo.info.administrative_area_level_1)[0]

        if(!additional){
            state.city = locationInfo.city
            updateStateOptions && (state.stateOptions = stateOptions)
            state.state = stateName
            state.country = country
        } else {
            addressField && (state.aboutTheLocation.address = locationInfo.formatted_address)
            state.aboutTheLocation.city = locationInfo.city ? locationInfo.city : locationInfo.info.administrative_area_level_1
            state.aboutTheLocation.stateOptions = stateOptions
            state.aboutTheLocation.state = stateName
            state.aboutTheLocation.country = country
            locationInfo.info?.postal_code && (state.aboutTheLocation.zip = locationInfo.info.postal_code)
        }
    })
}

export const handleSelected = (option, keyName, additional, state, usaStates, canadaStates) => {
    if(!additional) {

        state[keyName] = option
        option === 'USA' ? (state.stateOptions = usaStates) : (state.stateOptions = canadaStates)
    } else {
        state.aboutTheLocation[keyName] = option
        option === 'USA' ?
            (state.aboutTheLocation.stateOptions = usaStates) :
            (state.aboutTheLocation.stateOptions = canadaStates)
    }
}

export const handleTextarea = (state, status) => state.textarea.focus = status

export const defineFormData = (props) => {
    const source = props?.modal?.form?.aboutYourOrg || props.form?.aboutYourOrg || props?.form || props
    const countryOptions = source.countries.map(country => country.name)
    const industryOptions = source.industry.options
    const usaStates = source.countries.filter(countryObject => countryObject.name === 'USA')[0].states.map(elem => elem.name)
    const canadaStates = source.countries.filter(countryObject => countryObject.name !== 'USA')[0].states.map(elem => elem.name)

    return {
        countryOptions,
        industryOptions,
        usaStates,
        canadaStates
    }
}

export const defineStateOptions = (countryOptions, usaStates, canadaStates) => countryOptions[0] === 'USA' ? usaStates : canadaStates

export const clearForm = (state, v$, defaultCountry) => {
    state.firstName = ''
    state.lastName = ''
    state.role = ''
    state.phone = ''
    state.email = ''
    state.company = ''
    state.industry = ''
    state.city = ''
    state.state = ''
    state.country = defaultCountry
    state.source = ''
    state.aboutTheLocation.address = ''
    state.aboutTheLocation.addressAdditional = ''
    state.aboutTheLocation.city = ''
    state.aboutTheLocation.state = ''
    state.aboutTheLocation.country = defaultCountry
    state.aboutTheLocation.zip = ''
    state.textarea.value = ''
    state.subscribeEmail = false

    v$.firstName.$reset()
    v$.lastName.$reset()
    v$.email.$reset()
    v$.company.$reset()
    v$.industry.$reset()
    v$.phone.$reset()
    v$.country.$reset()
    v$.city.$reset()
    v$.state.$reset()
    v$.country.$reset()
}

export const getModalFormRecaptcha = (state, formName) => {
    return {
        formName,
        fields: [
            {name: 'firstName', value: state.firstName},
            {name: 'lastName', value: state.lastName},
            {name: 'role', value: state.role},
            {name: 'phone', value: state.phone},
            {name: 'email', value: state.email},
            {name: 'company', value: state.company},
            {name: 'industry', value: state.industry},
            {name: 'city', value: state.city},
            {name: 'state', value: state.state},
            {name: 'country', value: state.country},
            {name: 'source', value: state.source},
            {name: 'address1', value: state.aboutTheLocation.address},
            {name: 'address2', value: state.aboutTheLocation.addressAdditional},
            {name: 'locationCity', value: state.aboutTheLocation.city},
            {name: 'locationState', value: state.aboutTheLocation.state},
            {name: 'locationCountry', value: state.aboutTheLocation.country},
            {name: 'zipPostalCode', value: state.aboutTheLocation.zip},
            {name: 'message', value: state.textarea.value},
            {name: 'interested', value: state.subscribeEmail},
        ],
    }
}

export const getContactUsFormRecaptcha = (state, formName) => {
    return {
        formName,
        fields: [
            {name: 'firstName', value: state.firstName},
            {name: 'lastName', value: state.lastName},
            {name: 'role', value: state.role},
            {name: 'phone', value: state.phone},
            {name: 'email', value: state.email},
            {name: 'company', value: state.company},
            {name: 'industry', value: state.industry},
            {name: 'city', value: state.city},
            {name: 'state', value: state.state},
            {name: 'country', value: state.country},
            {name: 'source', value: state.source},
            {name: 'address1', value: state.aboutTheLocation.address},
            {name: 'address2', value: state.aboutTheLocation.addressAdditional},
            {name: 'locationCity', value: state.aboutTheLocation.city},
            {name: 'locationState', value: state.aboutTheLocation.state},
            {name: 'locationCountry', value: state.aboutTheLocation.country},
            {name: 'zipPostalCode', value: state.aboutTheLocation.zip},
            {name: 'message', value: state.textarea.value},
            {name: 'interested', value: state.subscribeEmail},
        ],
    }
}

export const getCareersFormRecaptcha = (state, formName, recaptchaToken) => {
  const formData = new FormData();
  formData.append('formName', formName);
  formData.append('firstName', state.firstName);
  formData.append('lastName', state.lastName);
  formData.append('role', state.role);
  formData.append('phone', state.phone);
  formData.append('email', state.email);
  formData.append('attachment', state.attachment);
  formData.append('referrer', state.referrer);
  formData.append('message', state.textarea.value);
  formData.append('recaptchaToken', recaptchaToken);

  return formData;
}

export const getMailingFormRecaptcha = (state, formName) => {
  return {
      formName,
      fields: [
        {name: 'firstName', value: state.firstname},
        {name: 'lastName', value: state.lastname},
        {name: 'email', value: state.email},
      ],
  }
}

export const changeInputStatus = (state, field) => state[field] = !state[field];

export const handleAutocomplete = (state, e, filedName) =>{
    if(!filedName) state.city = e.target.value
    if(filedName === 'aboutTheLocation') state.aboutTheLocation.city = e.target.value
    if(filedName === 'address') state.aboutTheLocation.address = e.target.value
}

export const getCookie = (name) => {
    // Split the document.cookie string into an array of cookies
    const cookies = document.cookie.split(';');

    // Loop through the cookies array to find the cookie with the specified name
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if the cookie name matches the specified name
      if (cookie.startsWith(name + '=')) {
        // Return the cookie value
        return cookie.substring(name.length + 1);
      }
    }

    // If the cookie is not found, return null
    return null;
}



